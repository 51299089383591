<template>
    <b-modal
        id="modal-edit-contract"
        ref="modal-edit-contract"
        centered
        :title="'Modifier le contrat '+(contract && contract.label ? contract.label : '')"
        @ok="update"
    >
        <div v-if="isLoadingContractDetail || isContractUpdating ||isContractDeleting">
            <div class="text-center">
                <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Chargement...</span>
                </div>
                <br />
                Chargement des détails...
            </div>
        </div>
        <validation-observer v-else ref="formEditContract">
            <b-form>
                <b-row>
                    <b-col cols="12">
                    <b-form-group
                        label="Libellé"
                        label-for="label"
                    >
                        <validation-provider
                        #default="{ errors }"
                        name="Label"
                        rules="required"
                        >
                        <b-form-input
                            id="label"
                            v-model="contract.label"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Renseigner un libellé"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #modal-footer="{ ok, cancel}">
        <div class="w-100">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-danger"
            class="float-left"
            @click="deleteContractLocal()"
        >
        Supprimer
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="float-right"
            @click="ok()"
        >
        Modifier
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="float-right mr-1"
            @click="cancel()"
        >
        Annuler
        </b-button>
        </div>
      </template>
    </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver, defineRule, configure, localize } from 'vee-validate';
import VSwatches from 'vue-swatches'
import { required, url, email } from '@validations';
import { mapGetters, mapActions } from "vuex";
import Ripple from 'vue-ripple-directive';
import 'vue-swatches/dist/vue-swatches.css'
configure({
  // Generates an English message locale generator
  generateMessage: localize('fr', {
    messages: {
        url: 'Ce champ doit être une URL',  
        required: 'Ce champ est requis',
        email: 'Ce champ doit être un email',
      
    },
  }),
});
localize('fr')

export default {
    props: {
        id: 0,
    },
    data() {
        return {
            required,
            url,
            email,
        }
    },
    methods: {
        deleteContractLocal() {
            this.$bvModal
            .msgBoxConfirm('Cette action est irréversible', {
                title: 'Êtes-vous sûr de vouloir supprimer le contrat "'+ this.contract.label +'" ?',
                size: 'sm',
                okVariant: 'danger',
                okTitle: 'Supprimer',
                cancelTitle: 'Annuler',
                cancelVariant: 'outline-primary',
                hideHeaderClose: true,
                centered: true,
            })
            .then(value => {
                if (value) {
                    this.deleteContract({id: this.contract.id, that: this})
                    this.$nextTick(() => {
                        this.$refs['modal-edit-contract'].toggle('#toggle-btn')
                    })
                }
            })
        },
        update(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.$refs.formEditContract.validate().then(success1 => {
                if (success1) {
                    this.updateContract({contract: this.contract, that: this})
                    this.$nextTick(() => {
                        this.$refs['modal-edit-contract'].toggle('#toggle-btn')
                    })
                }
            })
            
        },
        ...mapActions(["updateContract", "deleteContract", "fetchDetailContract"]),
    },
    computed: {
        contract() {
            return this.$store.getters.contract;
        },
        ...mapGetters(["isContractUpdating", "deletedDataContract", "isContractDeleting", "updatedDataContract", "isLoadingContractDetail"]) 
    },
    watch: {
        contract: {
            handler(n, l) {
                this.$store.dispatch("updateContractInput", n);
            },
            deep: true
        },
        updatedDataContract: function () {
            if (this.updatedDataContract !== null && !this.isContractUpdating) {
                this.$bvToast.toast('Le contrat "'+this.updatedDataContract.label+'" a bien été modifié', {
                    title: `Modification du contrat`,
                    toaster: 'b-toaster-bottom-right',
                    solid: true,
                    appendToast: true,
                    variant: 'success'
                })
            }
        },
        id() {           
            this.fetchDetailContract({id: this.id, that: this});
        }
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        VSwatches
    },
    directives: {
        Ripple,
    },

}
</script>

<style>

</style>