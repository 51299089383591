<template>
  <b-modal
    id="modal-create-contract"
    ref="modal-create-contract"
    centered
    title="Ajouter un contrat"
    @ok="create"
  >
    <div v-if="isCreatingContract">
      <div class="text-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <br />
        Chargement des contrats...
      </div>
    </div>
    <validation-observer v-else ref="formCreateContract">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Libellé" label-for="label">
              <validation-provider
                #default="{ errors }"
                name="Label"
                rules="required"
              >
                <b-form-input
                  id="label"
                  v-model="contractForm.label"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Renseigner un libellé"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
    </validation-observer>
    <template #modal-footer="{ ok, cancel}">
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right"
          @click="ok()"
        >
          Ajouter
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          class="float-right mr-1"
          @click="cancel()"
        >
          Annuler
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  defineRule,
  configure,
  localize
} from 'vee-validate'
import VSwatches from 'vue-swatches'
import { required, url, email } from '@validations'
import { mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import 'vue-swatches/dist/vue-swatches.css'
configure({
  // Generates an English message locale generator
  generateMessage: localize('fr', {
    messages: {
      url: 'Ce champ doit être une URL',
      required: 'Ce champ est requis',
      email: 'Ce champ doit être un email'
    }
  })
})
localize('fr')

export default {
  data () {
    return {
      required,
      url,
      email,
      contractForm: {
        label: '',
        workspaceId: this.$store.getters.workspaceSelected
      }
    }
  },
  methods: {
    popupCreateContractEvent (id) {
      if (id != '') {
        this.fetchContract(id).then(res => {
          this.contractForm = res
        })
      } else {
        this.initializeForm()
      }
    },
    initializeForm () {
      this.contractForm = {
        label: '',
        workspaceId: this.$store.getters.workspaceSelected
      }
    },
    create (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.formCreateContract.validate().then(success1 => {
        if (success1) {
          if(this.contractForm.id) this.updateContract(this.contractForm)
          else this.createContract(this.contractForm)
          this.$nextTick(() => {
            this.$refs['modal-create-contract'].toggle('#toggle-btn')
          })
        }
      })
    },
    ...mapActions(['createContract', 'fetchContract', 'updateContract'])
  },
  computed: {
    ...mapGetters(['isCreatingContract', 'contract'])
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    VSwatches
  },
  directives: {
    Ripple
  }
}
</script>

<style></style>
