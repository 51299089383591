<template lang="pug">
    div
      b-button.mb-2(variant="primary", v-b-modal.modal-create-contract @click="popupCreateContractEvent('')") Ajouter
      b-col(md="12")
        b-form-group(label-cols-sm="10", label-align-sm="right", label-size="sm", label-for="filterInput")
          b-input-group(size="sm")
            b-form-input#filterInput(v-model="filter", type="search", placeholder="Rechercher")
            b-input-group-append
              b-button.btn-icon(:disabled="!filter", @click="filter = ''")
                feather-icon(icon="DeleteIcon")
      div(v-if="isLoadingContractsList")
        .text-center
          .spinner-border.text-primary(role="status")
            span.sr-only Chargement...
          br
          | Chargement des types de contrats...
      b-table(v-else, :striped="true", :hover="true", :items="contractsList", :fields="fields", :sort-by.sync="sortBy", :sort-desc.sync="sortDesc", :sort-direction="sortDirection", :filter="filter", :filter-included-fields="filterOn")
        template(#cell(color)="data")
          .color-dot(:style="'background-color:'+data.item.color")
        template(#cell(label)="data") {{data.item.label}}
        template(#cell(description)="data") {{data.item.description }}
        template(#cell(actions)="data")
          b-button-group
            b-button.btn-icon(v-ripple.400="'rgba(113, 102, 240, 0.15)'", variant="outline-primary", v-b-modal.modal-create-contract, @click="popupCreateContractEvent(data.item.id)")
              feather-icon(icon="EditIcon")
            b-button.btn-icon(variant="outline-danger", v-ripple.400="'rgba(113, 102, 240, 0.15)'", @click="deleteContractLocal(data.item.id, data.item.label)")
              feather-icon(icon="TrashIcon")
      popup-create-contract(ref="popupCreateContract")
</template>
<script>
import {
  BTable,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
  BButtonToolbar,
  BButtonGroup,
  BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'
import PopupCreateContract from '@/components/directory/contract/popup-contract/PopupCreateContract'
import PopupEditContract from '@/components/directory/contract/popup-contract/PopupEditContract'
export default {
  components: {
    BTable,
    BFormGroup,
    BCardBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    PopupCreateContract,
    PopupEditContract
  },
  directives: {
    Ripple
  },
  data () {
    return {
      idContractUpdate: '',
      fields: [
        { key: 'label', label: 'Libellé', sortable: true },
        { key: 'actions', label: 'Actions' }
      ],
      
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: []
    }
  },
  computed: {
    ...mapGetters([
      'contractsList',
      'isLoadingContractsList'
    ])
  },
  watch: {
    deletedDataContract: function () {
      if (this.deletedDataContract !== null && !this.isContractDeleting) {
        this.$bvToast.toast(
          'Le contrat "' + this.deletedDataContract.label + '" a été supprimé',
          {
            title: `Suppression du contrat`,
            toaster: 'b-toaster-bottom-right',
            solid: true,
            appendToast: true,
            variant: 'success'
          }
        )
      }
    }
  },
  methods: {
    popupCreateContractEvent (id) {
      this.$refs['popupCreateContract'].popupCreateContractEvent(id)
    },
    deleteContractLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Cette action est irréversible', {
          title:
            'Êtes-vous sûr de vouloir supprimer le contrat "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteContract(id)
          }
        })
    },
    ...mapActions(['fetchContractsList', 'deleteContract'])
  },
  created () {
    this.fetchContractsList()
  }
}
</script>
<style scoped>
.color-dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}
</style>
